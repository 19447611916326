class AppConfig {
    static BALLOON_API_PATH = (host) => AppConfig.getRootPath(host) + "app/lib/php/api/v1/"
    static BALLOON_APP_PATH = (host) => AppConfig.getRootPath(host) + "app/lib/php/app.php"
    static A_TYPE_BALLOON = "Баллонный газ"
    static A_TYPE_CAPACITY = "Емкостной газ"
    static METER_INPUT_COUNT_LAST_DAY = 27
    static LOGO_ALTAY = "logo.png"
    static LOGO_SIBGAZ = "sLogo.png"
    static ROOT_PATH_ALTAY = "/"
    static ROOT_PATH_SIBGAZ = "/gaz/"
    static DOCUMENT_PP_ALTAY_LOCATION = "docs/PrivacyPolicyAkgs.doc"
    static DOCUMENT_PP_SIBGAZ_LOCATION = "docs/PrivacyPolicySibgaz.doc"
    static DOCUMENT_UA_ALTAY_LOCATION = "docs/UseAgreementAkgs.doc"
    static DOCUMENT_UA_SIBGAZ_LOCATION = "docs/UseAgreementSibgaz.doc"

    static getDocumentsLocation(host) {
        return {
            PP: host === "altay" ? this.ROOT_PATH_ALTAY + this.DOCUMENT_PP_ALTAY_LOCATION : this.ROOT_PATH_SIBGAZ + this.DOCUMENT_PP_SIBGAZ_LOCATION,
            UA: host === "altay" ? this.ROOT_PATH_ALTAY + this.DOCUMENT_UA_ALTAY_LOCATION : this.ROOT_PATH_SIBGAZ + this.DOCUMENT_UA_SIBGAZ_LOCATION
        }
    }

    static getRootPath(host) {
        return host === "altay" ? this.ROOT_PATH_ALTAY : this.ROOT_PATH_SIBGAZ
    }

    static getLogoImagePath(host, page) {
        const logo = host === "altay" ? this.LOGO_ALTAY : this.LOGO_SIBGAZ
        const path = page === "modules" ? "../../lib/img/" : "app/lib/img/"

        return path + logo
    }
}

export default AppConfig