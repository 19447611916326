import React from "react"

export default class Loader extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return(
            <div className="spinner-border loader-primary-color" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        )
    }
}