import axios from 'axios';
import moment from '@balloon/app/lib/js/moment';
import queryString from 'querystring';
import {requestOrders, ui} from '@balloon/app/lib/js/app';

export default class PaymentTools {

    config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    };

    /**
     * Метод кодирует полезную нагрузку
     *
     * @param data Полезная нагрузка
     * @returns {Promise<*>}
     */
    async encodePayload(data) {
        let encodedData;

        await axios.post(`../../lib/php/api/payment/encode/`, data, this.config).then(r => {
            if (process.env.NODE_ENV === 'development') {
                console.log(r);
            }

            const body = r.data;
            const url = body.url;
            const token = body.token;

            encodedData = {
                url:   url,
                token: token
            };
        });

        return encodedData;
    }

    /**
     * Метод создает транзакцию в системе интернет-эквайринга Сбербанка
     *
     * @param amount Сумма оплаты
     */
    createPaymentTransaction(amount) {
        const user = sessionStorage.getItem('user');
        const _userObject = JSON.parse(user);

        if (process.env.NODE_ENV === 'development') {
            console.log(_userObject);
        }

        const _dataObj = {
            login:           _userObject['session_login'],
            abonentFullName: _userObject['session_fio'],
            abonentPhone:    _userObject['session_phone'],
            orderNumber:     `debt_${new Date().getTime()}`,
            documentDate:    moment(new Date(), 'DD/MM/YYYY').format('DD.MM.YYYY'),
            amount:          parseFloat(amount)
        };

        const data = queryString.stringify(_dataObj);
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };

        this.encodePayload(data).then(async encodedData => {

            if (process.env.NODE_ENV === 'development') {
                console.log(_dataObj, encodedData);
            }

            const payloadData = queryString.stringify({
                validate: encodedData.token
            });

            await axios.post(`${encodedData.url}/payment/create/`, payloadData, config).then(r => {
                if (r.status === 200) {
                    if (process.env.NODE_ENV === 'development') {
                        console.log(`Redirect to payment page ${r.data.formUrl}`);
                    }
                    window.location.replace(r.data.formUrl);
                } else {
                    if (process.env.NODE_ENV === 'development') {
                        console.log(`Create transaction order error`);
                    }
                    ui.showModalMessage('Внимание', `Ошибка при оплате заказа: \n Код ошибки: ${r.data.errorCode};\n Сообщение: ${r.data.errorMessage}`, 'warning');
                }
            });
        }).catch(err => {
            console.log(err);
        });
    }

    /**
     * Метод проверяет транзакцию в системе интернет-эквайринга Сбербанка
     *
     * @param orderData Объект с данными для отправки запроса
     */
    async checkPaymentTransaction(orderData) {
        ui.toggleButtonState('off');
        const user = sessionStorage.getItem('user');
        const _userObject = JSON.parse(user);
        let checkResult = null;

        console.log(`Check if document ${orderData.orderNumber} exist`);
        await this.checkDocumentAlreadyExist(_userObject['session_login'], orderData.orderNumber).then(r => {
            checkResult = r;
        });

        if (!checkResult.exist) {
            const _dataObj = {
                login:       _userObject['session_login'],
                orderId:     orderData.orderId,
                orderNumber: orderData.orderNumber,
                lang:        orderData.lang
            };

            const data = queryString.stringify(_dataObj);

            const config = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            };

            this.encodePayload(data).then(async encodedData => {
                if (process.env.NODE_ENV === 'development') {
                    console.log(_dataObj, encodedData);
                }

                const payloadData = queryString.stringify({
                    validate: encodedData.token
                });

                await axios.post(`${encodedData.url}/payment/check/`, payloadData, config).then(async r => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log(r);
                    }

                    if (r.status === 200) {

                        const _payloadData = {
                            l:           _userObject['session_login'],
                            acquiringId: orderData.orderNumber,
                            orderId:     orderData.orderId,
                            paymentDate: orderData.documentDate,
                            sum:         orderData.amount / 100,
                            method:      'createCapacityPaymentDocument'
                        };

                        await axios.post('/app/lib/php/app.php', queryString.stringify(_payloadData), config).then(_r => {
                            if (process.env.NODE_ENV === 'development') {
                                console.log(_r);
                            }

                            if (_r.status === 200) {
                                console.log(_payloadData);

                                // Отправляем смс
                                requestOrders.sendPost(
                                    'app.php',
                                    'optional',
                                    'sendSms' +
                                    '&l=' + _payloadData.l +
                                    '&message=' + `Оплата на сумму ${_payloadData.sum}руб. произведена для абонента ${_userObject['session_fio']}`
                                );

                                document.location.replace(`/app/modules/devices/?orderNumber=${orderData.orderNumber}&m=paymentSuccessful`);

                            } else {
                                ui.showModalMessage('Внимание', `Документ оплаты не создан, просьба обратиться в техническую поддержку с номером транзакции № ${orderData.orderNumber}`, 'warning');
                                ui.toggleButtonState('on');
                            }
                        });
                    } else {
                        ui.showModalMessage('Внимание', `Проверка заказа № ${orderData.orderNumber} завершилась неудачей`, 'warning');
                        ui.toggleButtonState('on');
                    }
                });
            }).catch(err => {
                console.log(err);
                ui.toggleButtonState('on');
            });
        } else {
            ui.showModalMessage('Внимание', `Заказа № ${orderData.orderNumber} уже существует, обратитесь в тех. поддержку`, 'warning');
            ui.toggleButtonState('on');
        }
    }

    /**
     * Метод проверяет, существует ли в системе уже документ с таким идентификатором, была ли ранее произведена оплата
     *
     * @param login Логин абонента
     * @param orderId Код заказа эквайринга
     * @returns {Promise<{exist: boolean, err: null, array: *, status: *} | {exist: null, err: *, array: null, status: number}>}
     */
    async checkDocumentAlreadyExist(login, orderId) {
        const _dataObj = {
            method:  'checkPaymentDocumentExist',
            l:       login,
            orderId: orderId
        };

        const data = queryString.stringify(_dataObj);

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };

        return axios.post('/app/lib/php/app.php', data, config).then(async r => {
            if (process.env.NODE_ENV === 'development') {
                console.log(`Количество найденных документов: ${r.data.answer.length}`);
            }

            return {
                exist:  r.data.answer.length > 0,
                status: r.status,
                array:  r.data,
                err:    null
            };
        }).catch(err => {
            console.log(err);
            return {
                exist:  null,
                status: 500,
                array:  null,
                err:    err
            };
        });
    }

}
