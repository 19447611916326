import React, {Component} from "react"

class MainLogo extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return(
            // app/lib/img/logo.png     - main index
            // ../../lib/img/logo.png   - orders/devices index

            <img id="img-logo"
                 className="img-icon-wrapper img-logo"
                 src={this.props.img}
                 alt="Logo" />
        )
    }
}

export default MainLogo