import jwt from 'jsonwebtoken';
import axios from 'axios';

/**
 * Класс представляет методы доступа к новому API
 */
class GazoilApi {

    /**
     * Метод возвращает подписанный токен для доступа к API
     * @return {string} Закодированный токен
     */
    static _getApiToken() {
        const token = jwt.sign(
            { apiKey: process.env.G_API_KEY },
            process.env.JWT_SECRET,
            {
                expiresIn: process.env.G_API_EXPIRES_IN
            });

        if (process.env.NODE_ENV === 'development') {
            console.log(token);
        }

        return token;
    }

    /**
     * Метод реализует GET запрос на url с параметрами
     * @param {string} url url запроса
     * @param {Object} params параметры запроса
     * @return {Promise<AxiosResponse<any>>} Promise с ответом
     */
    static GET(url, params) {
        const headers = {
            'Authorization': `Bearer ${this._getApiToken()}`
        };

        return axios.get(url, {
            params,
            headers: headers
        });
    }

    /**
     * Метод реализует POST запрос на url с параметрами
     * @param {string} url url запроса
     * @param {Object} params параметры запроса
     * @return {Promise<AxiosResponse<any>>} Promise с ответом
     */
    static POST(url, params) {
        const headers = {
            'Authorization': `Bearer ${this._getApiToken()}`
        };

        return axios.post(url, params, {
            headers: headers
        });
    }

    /**
     * Метод реализует проверку доступа к API с текущим токеном
     */
    static checkAccess() {
        this.GET(`${process.env.G_API_URL}/auth/check`)
            .then((response) => {
                // обработка успешного запроса
                console.log(response);
            })
            .catch((error) => {
                // обработка ошибки
                console.log(error);
            })
            .then(() => {
                // выполняется всегда
            });
    }

    //region Работа с пользователями
    /**
     * Метод реализует обновление пользователями, данными из 1С
     */
    static updateUser() {
        // updateUser
    }

    //endregion
}

export default GazoilApi;
