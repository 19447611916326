import React, {createElement} from "react"
import {inputID, inputSignInLogin, inputSignInPassword, requestProfile, ui} from "@balloon/app/lib/js/app"
import AppConfig from "@/js/appConfig"
import ReactDOM from "react-dom"
import Loader from "@/js/components/Loader.jsx"

export default class ProfileActionButtons extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            goToButton: {
                text: " К заказам",
                method: this.toOrders
            }
        }

        this.componentDidMount = this.componentDidMount.bind(this)
    }

    logOut = () => {
        ui.onLoad("show");
        requestProfile.sendPost("app.php", "form-sign-in", "logOut", () => {
            ui.updater("logOut");

            inputSignInLogin.val("");
            inputSignInPassword.val("");
        });
    }

    updateUser = () => {
        ui.onLoad("show");
        requestProfile.sendPost(
            "app.php",
            "optional",
            "signIn&getUser=" + inputID.val(),
            (callbackReturn) => {
                let response = jQuery.parseJSON(JSON.stringify(callbackReturn));

                if (response["auth_user_success"] !== "null") {
                    requestProfile.sendPost("app.php", "optional", "updateUser&l=" + inputID.val(), (_callbackReturn) => {
                        if (process.env.NODE_ENV === "development") {
                            console.log(_callbackReturn)
                        }
                        ui.updateProfileForm(_callbackReturn);
                    });
                } else {
                    ui.showModalMessage("Внимание", "Ошибка! Обратитесь в техподдержку", "error");
                }
            }
        );
        ReactDOM.render(createElement(Loader), document.getElementById("div-profile-info"));
        ui.showProfileRightPanelInfo();
    }

    toOrders = () => {
        ui.onLoad("show");
        ui.goToPage(0, "app/modules/orders/");
    }

    toDevices = () => {
        ui.onLoad("show");
        ui.goToPage(0, "app/modules/devices/");
    }

    componentDidMount() {
        if (this.props.w === AppConfig.A_TYPE_CAPACITY)
            this.setState({
                goToButton: {
                    text: "К приборам учета",
                    method: this.toDevices
                }
            })
    }

    render() {
        return (
            <div className="w-100">
                <div id="div-profile-buttons--inner-wrapper"
                     className="input-group-append d-flex flex-column flex-sm-row justify-content-md-center">
                    <button id="button-to-orders"
                            onClick={this.state.goToButton.method}
                            className="btn btn-primary mr-0 mr-sm-3 mt-3 mt-sm-0 elevation-active-2" type="button">
                        {this.state.goToButton.text}
                    </button>
                    <button id="button-update-user"
                            onClick={this.updateUser}
                            className="btn btn-grey mr-0 mr-sm-3 mt-3 mt-sm-0 elevation-active-2" type="button"
                    >Загрузить данные
                    </button>
                    <button id="button-log-out"
                            onClick={this.logOut}
                            className="btn btn-warning logout-button mt-3 mt-sm-0 elevation-active-2 ignore-disable"
                            type="button">Выход
                    </button>
                </div>
            </div>
        )
    }
}
