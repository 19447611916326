import React from 'react';
import AppConfig from '@/js/appConfig';

export default class ProfileRightPanelInfo extends React.Component {
    constructor(props) {
        super(props);
    }

    acTypeCapacityInfo(props) {
        const i = props.info;
        const residentGroups = i['residents'];
        if (process.env.NODE_ENV === 'development') {
            console.log(i);
            Object.entries(residentGroups).map(rg => console.log(rg[1]['Resident']));
        }

        if (props.show) {
            return <div>
                <span>Тариф: {i['tariff']}</span><br/>
                <a className="btn btn-sm btn-block btn-grey my-3" data-toggle="collapse"
                   href="#profileResidentsCollapse" role="button"
                   aria-expanded="false" aria-controls="profileResidentsCollapse">
                    <span>Проживающие</span> <i className="fas fa-sort"> </i>
                </a>
                <div className="collapse my-3" id="profileResidentsCollapse">
                    <table className="table table-bordered table-sm">
                        <thead>
                        <tr>
                            <th scope="col">Статус</th>
                            <th scope="col">ФИО</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            Object.entries(residentGroups).map(rg => {
                                return Array.isArray(rg[1]['Resident']) ?
                                       rg[1]['Resident'].map((r, key) =>
                                           <tr key={key}>
                                               <td>{r['Status']}</td>
                                               <td>{r['FIO']}</td>
                                           </tr>
                                       ) :
                                       <tr key={0}>
                                           <td>{rg[1]['Resident']['Status']}</td>
                                           <td>{rg[1]['Resident']['FIO']}</td>
                                       </tr>;
                            })
                        }
                        </tbody>
                    </table>
                </div>
                <span>Сальдо: <b>{i['debt']}</b> <i className="fas fa-ruble-sign"> </i></span>
            </div>;
        }
    }

    render() {
        const user = this.props.user;
        const condition = user['session_1CAccountType'] === AppConfig.A_TYPE_CAPACITY;
        if (process.env.NODE_ENV === 'development') {
            console.log("Пользователь, условие:");
            console.log(user, condition);
            console.log(`[ProfileRightPanelInfo:render] Capacity: ${this.props.capacity}`);
        }

        return (
            <div className="col-12 alert alert-custom elevation-custom">
                <span id="span-fio">ФИО: {user['session_fio']}</span><br/>
                <div className="divider my-3"></div>
                <span id="span-phone">Тел: +7{user['session_phone']}</span>
                {
                    this.acTypeCapacityInfo({
                        show: condition,
                        info: this.props.capacity
                    })
                }
            </div>
        )
    }
}
