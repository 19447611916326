'use strict';

//region Import
import '@balloon/app/lib/css/colors.css';
import '@balloon/app/lib/css/loader.css';
import '@balloon/app/lib/css/all.css';
import UserInterface from '@balloon/app/lib/js/UserInterface';
import Request from '@balloon/app/lib/js/Request';
import moment from 'moment';
import AppConfig from '@/js/appConfig';
import GazoilApi from '@/js/classes/GazoilApi';

//region Variables
let requestProfile = null;
let requestOrders = null;
let orderList = [];
let ui = null;
let divAuthorizationForm = null;
let divUserProfile = null;
let divCurrentSumTooltip = null;
let divOrder = null;
let pTextHeaderDescriptionProfile = null;
let divLoader = null;
let divModalDataProcessing = null;
let inputModalDataProcessingCheckbox = null;
let divModal = null;
let pModalTitle = null;
let spanModalHeaderBadge = null;
let divModalBody = null;
let buttonModalDismiss = null;
let buttonModalAdditional = null;
let selectRegion = null;
let inputID = null;
let inputFio = null;
let inputDistrict = null;
let inputCity = null;
let inputStreet = null;
let inputBuilding = null;
let inputApartment = null;
let inputPhone = null;
let inputSignInLogin = null;
let inputSignInPassword = null;
let inputPassword = null;
let inputPasswordRepeat = null;
let inputBalloonType = null;
let selectDeliveryType = null;
let inputBalloonNumbers = null;
let selectDeliveryDate = null;
let inputOrderComment = null;
let inputCurrentSum = null;
let labelCurrentSumPrepend = null;
let spanCurrentSumAppend = null;
let spanCreatedDateOrStatus = null;
let inputResetPassword = null;
let aShowResetForm = null;
let spanFio = null;
let spanPhone = null;
let spanLogin = null;
let spanStatus = null;
let spanRoute = null;
let spanAccount = null;
let buttons = null;
let buttonSignIn = null;
let buttonLogOut = null;
let buttonUpdateUser = null;
let buttonToOrders = null;
let buttonToProfile = null;
let buttonLogOutOrders = null;
let buttonAboutStatusDisclaimer = null;
let buttonUpdateUserPassword = null;
let buttonConfirmDataProcessing = null;
let buttonRefreshOrdersList = null;
let buttonCheckBalloonsNumber = null;
let buttonCreateOrder = null;
let buttonUpdateOrder = null;
let buttonDeleteEditOrder = null;
let buttonCloseEditButton = null;
let buttonResetPassword = null;
let tableOrdersList = null;
let tableOrderListObject = {};
let pHeaderText = null;
let iLoginHelp = null;
let divProfileRightSideInfo = null;
let divProfileRightSideButtons = null;
//endregion

//endregion

requestProfile = new Request('app/lib/php/');
requestOrders = new Request('../../lib/php/');
ui = new UserInterface();
window.ui = ui;

$(document).ready(() => {

    //region Получение элементов в переменные
    divAuthorizationForm = $('#div-authorization-form');
    divUserProfile = $('#div-user-profile');
    divCurrentSumTooltip = $('#div-current-sum-tooltip');
    divOrder = $('#div-order');

    pTextHeaderDescriptionProfile = $('#text-header-description-page');
    divLoader = $('#div-loader');
    divModalDataProcessing = $('#div-modal-data-processing');
    divModal = $('#div-modal');
    pModalTitle = $('#p-modal-title');
    spanModalHeaderBadge = $('#span-modal-header-badge');
    divModalBody = $('#div-modal-body');
    buttonModalDismiss = $('#button-modal-dismiss');
    buttonModalAdditional = $('#button-modal-additional');

    aShowResetForm = $('#a-show-reset-form');
    selectRegion = $('#select-region');
    inputID = $('#input-id');
    inputFio = $('#input-fio');
    inputDistrict = $('#input-district');
    inputCity = $('#input-city');
    inputStreet = $('#input-street');
    inputBuilding = $('#input-building');
    inputApartment = $('#input-apartment');
    inputPhone = $('#input-phone');
    inputSignInLogin = $('#input-sign-in-login');
    inputSignInPassword = $('#input-sign-in-password');
    inputPassword = $('#input-password');
    inputPasswordRepeat = $('#input-password-repeat');

    inputBalloonType = $('#input-balloon-type');
    selectDeliveryType = $('#select-delivery-type');
    inputBalloonNumbers = $('#input-balloon-numbers');
    selectDeliveryDate = $('#select-delivery-date');
    inputOrderComment = $('#input-order-comment');
    inputCurrentSum = $('#input-current-sum');
    labelCurrentSumPrepend = $('#label-current-sum-prepend');
    spanCurrentSumAppend = $('#span-current-sum-append');
    spanCreatedDateOrStatus = $('#span-order-created-date-or-status');
    inputModalDataProcessingCheckbox = $('#input-modal-data-processing-checkbox');
    inputResetPassword = $('#input-reset-password');

    spanFio = $('#span-fio');
    spanPhone = $('#span-phone');
    spanLogin = $('#span-login');
    spanStatus = $('#span-status');
    spanRoute = $('#span-route');
    spanAccount = $('#span-account');

    // buttons = $("button:not('button.ignore-disable')");
    buttonSignIn = $('#button-sign-in');
    buttonLogOut = $('#button-log-out');
    buttonUpdateUser = $('#button-update-user');
    buttonToOrders = $('#button-to-orders');
    buttonToProfile = $('#button-to-profile');
    buttonLogOutOrders = $('#button-log-out-orders');
    buttonAboutStatusDisclaimer = $('#button-about-status-disclaimer');
    buttonUpdateUserPassword = $('#button-update-user-password');
    buttonConfirmDataProcessing = $('#button-confirm-data-processing');
    buttonCheckBalloonsNumber = $('#button-check-balloon-number');
    buttonRefreshOrdersList = $('#button-refresh-orders-list');
    buttonCreateOrder = $('#button-create-order');
    buttonUpdateOrder = $('#button-update-order');
    buttonDeleteEditOrder = $('#button-delete-edit-order');
    buttonCloseEditButton = $('#button-close-edit-order');
    buttonResetPassword = $('#button-reset-password');

    pHeaderText = $('#p-header-text-order');

    iLoginHelp = $('#i-login-help');

    divProfileRightSideInfo = document.getElementById('div-profile-info');
    divProfileRightSideButtons = document.getElementById('div-profile-buttons');
    //endregion

    // Подсказка для входа
    let loginHelpBodyTooltip = '<div class=\'p-1\'>Войти на сайт можно двумя способами:<br><br>' + '<span class=\'badge badge-grey text-uppercase py-1\'>По номеру телефона:</span> - шаблон для ввода:<br>(без восьмерки/семерки): 9609609090<br><br>' + '<span class=\'badge badge-grey text-uppercase py-1\'>По вводу ID абонента:</span> - шаблон для ввода:<br>b00000001<br>s00000001</div>';

    ui.tooltip('create', '#' + iLoginHelp.attr('id'), loginHelpBodyTooltip, 'mouseenter');

    ui.showProfileRightPanelInfo();
    ui.showProfileActionButtons();

    //region Разные слушатели событий
    buttonSignIn.click(async () => {
        ui.checkSignInCredentials(() => {

            GazoilApi.checkAccess();

            return;

            ui.checkLoginOrPhone(inputSignInLogin.val(), (value) => {
                if (value === 'login') {
                    ui.onLoad('show');

                    // TODO: Переделать в будущем на новое API ?? 
                    requestProfile.sendPost('app.php', 'form-sign-in', 'signIn', async (callbackReturn) => {
                        let response = jQuery.parseJSON(JSON.stringify(callbackReturn));
                        let dataProcessing = response['session_dataProcessing'];

                        if (process.env.NODE_ENV === 'development') {
                            console.log(response);
                            if (response['blockUser'] !== undefined) {
                                console.log(response['blockUser']['condition']);
                                console.log(response['blockUser']['cropLogin']);
                                console.log(response['blockUser']['typeIndex']);
                            }
                        }

                        if (response['auth_user_success'] !== 'null') {

                            return;

                            requestProfile.sendPost('app.php', 'form-sign-in', 'updateUser', (callbackReturn) => {
                                ui.checkDataProcessing(dataProcessing);
                                ui.updateProfileForm(callbackReturn);

                                ui.showProfileRightPanelInfo();
                                ui.showProfileActionButtons();
                            });
                        } else {
                            ui.showModalMessage('Внимание', 'Логин или пароль неверный', 'warning');
                            ui.onLoad('hide');

                            inputSignInLogin.val('');
                            inputSignInPassword.val('');
                        }
                    });
                } else {
                    ui.onLoad('show');
                    requestProfile.sendPost('app.php', 'optional', 'getUser&type=' + value + '&loginOrPhone=' + inputSignInLogin.val(), (callbackReturn) => {
                        let response = jQuery.parseJSON(JSON.stringify(callbackReturn));
                        let login = response['id'];

                        ui.onLoad('show');
                        requestProfile.sendPost('app.php', 'optional', 'signIn&l=' + response['id'] + '&p=' + inputSignInPassword.val(), (callbackReturn) => {
                            let response = jQuery.parseJSON(JSON.stringify(callbackReturn));
                            let dataProcessing = response['session_dataProcessing'];

                            if (response['auth_user_success'] !== 'null') {
                                requestProfile.sendPost('app.php', 'optional', 'updateUser&l=' + login, (callbackReturn) => {
                                    ui.checkDataProcessing(dataProcessing);
                                    ui.updateProfileForm(callbackReturn);

                                    ui.showProfileRightPanelInfo();
                                    ui.showProfileActionButtons();
                                });
                            } else {
                                ui.showModalMessage('Внимание', 'Логин или пароль неверный', 'warning');
                                ui.onLoad('hide');

                                inputSignInLogin.val('');
                                inputSignInPassword.val('');
                            }
                        });
                    }, () => {
                        ui.showModalMessage('Внимание', 'Пользователь с таким номером не найден', 'warning');
                        ui.onLoad('hide');
                    });
                }
            });
        });
    });

    aShowResetForm.click(() => {
        ui.showHideElement($('#form-reset-password'));
    });

    buttonResetPassword.click(function (e) {
        e.preventDefault();
    }).click(() => {
        ui.checkLoginOrPhone(inputResetPassword.val(), (value) => {
            ui.onLoad('show');
            requestProfile.sendPost('app.php', 'form-reset-password', 'getUser&type=' + value, (callbackReturn) => {
                let response = jQuery.parseJSON(JSON.stringify(callbackReturn));
                let min = Math.ceil(10000000);
                let max = Math.floor(99999999);
                let generatedPassword = Math.floor(Math.random() * (max - min + 1)) + min;
                let phone = response['phone'];
                let login = response['id'];

                if (response['answer'] !== 'null') {
                    requestProfile.sendPost('app.php', 'optional', 'updateUserPassword&l=' + login + '&p=' + generatedPassword, (callbackReturn) => {
                        let response = jQuery.parseJSON(JSON.stringify(callbackReturn));
                        let message = `Логин ${login} Пароль: ${generatedPassword}`;

                        if (response['update_user_password_success'] === 'Done') {
                            requestProfile.sendPost('app.php', 'optional', 'sendSms&l=' + login + '&phone=' + phone + '&message=' + message, () => {
                                inputResetPassword.val('');
                                ui.onLoad('hide');
                                ui.showModalMessage('Внимание', 'СМС с новым паролем отправлена', 'primary');
                            }, () => {
                                ui.onLoad('hide');
                            });
                        }
                    }, () => {
                        console.log('ERROR: Password not updated');
                        ui.onLoad('hide');
                    });
                } else {
                    ui.showModalMessage('Внимание', 'Пользователь не найден', 'warning');
                    ui.onLoad('hide');
                }
            });
        });
    });

    // buttonLogOut.click(() => {
    //     ui.onLoad("show");
    //     requestProfile.sendPost("app.php", "form-sign-in", "logOut", () => {
    //         ui.updater("logOut");
    //
    //         inputSignInLogin.val("");
    //         inputSignInPassword.val("");
    //     });
    // });
    //
    // buttonUpdateUser.click(() => {
    //     ui.onLoad("show");
    //     requestProfile.sendPost(
    //         "app.php",
    //         "optional", // optional in this context
    //         "signIn&getUser=" + inputID.val(),
    //         (callbackReturn) => {
    //             let response = jQuery.parseJSON(JSON.stringify(callbackReturn));
    //
    //             if (response["auth_user_success"] !== "null") {
    //                 requestProfile.sendPost("app.php", "optional", "updateUser&l=" + inputID.val(), (callbackReturn) => {
    //                     ui.updateProfileForm(callbackReturn);
    //                 });
    //             } else {
    //                 ui.showModalMessage("Внимание", "Ошибка! Обратитесь в техподдержку", "error");
    //             }
    //         }
    //     );
    // });
    //
    // buttonToOrders.click(() => {
    //     ui.onLoad("show");
    //     ui.goToPage(0, "app/modules/orders/");
    // });

    buttonToProfile.click(() => {
        ui.onLoad('show');
        ui.goToPage(0, '../../../');
    });

    buttonLogOutOrders.click(() => {
        ui.onLoad('show');
        requestOrders.sendPost('app.php', 'form-sign-in', 'logOut', () => {
            ui.goToPage(1500, '../../../');
        });
    });

    buttonAboutStatusDisclaimer.click(() => {
        ui.showModalMessage('Подробнее', ui.ORDER_ABOUT_STATUS_DISCLAIMER, 'light');
    });

    buttonUpdateUserPassword.click(() => {
        ui.checkPasswordIdentity(() => {
            ui.onLoad('show');
            requestProfile.sendPost('app.php', 'optional', 'updateUserPassword&l=' + inputID.val() + '&p=' + inputPassword.val(), () => {
                ui.onLoad('hide');
                inputPassword.val('');
                inputPasswordRepeat.val('');
                ui.showModalMessage('Успешно', 'Пароль успешно изменен', 'primary');
            });
        });
    });

    buttonConfirmDataProcessing.click(() => {
        let request = document.location.pathname === '/app/modules/orders/' ? requestOrders : requestProfile;
        let login = document.location.pathname === '/app/modules/orders/' ? spanLogin.text() : inputID.val();

        request.sendPost('app.php', 'optional', 'confirmDataProcessing&l=' + login, () => {
        });
    });

    inputBalloonNumbers.focus(() => {
        ui.toggleElementsControlState('off', '', '');
    });

    inputBalloonNumbers.change(() => {
        if (inputBalloonNumbers.val().toString().length > 2) {
            inputBalloonNumbers.val(inputBalloonNumbers.val().toString().substr(0, 2));
        }
    });

    buttonCheckBalloonsNumber.click(() => {
        ui.toggleElementsControlState('on', '', () => {
            ui.getDataToCreateOrder();
            ui.showDeliveryInfo(parseInt(inputBalloonNumbers.val()));
        });
    });

    selectDeliveryType.change(() => {
        ui.showDeliveryInfo(parseInt(inputBalloonNumbers.val()));

        let login = spanLogin.text();
        let balloonNumbers = inputBalloonNumbers.val();
        let deliveryType = selectDeliveryType.val();
        let deliveryDate = selectDeliveryDate.val();

        ui.toggleButtonState('off');
        ui.elementOnLoad(inputCurrentSum, 'on', 'grey');
        ui.elementOnLoad(inputBalloonType, 'on', 'grey');
        ui.tooltip('hideAll');

        requestOrders.sendPost('app.php', 'optional', 'getSum' + '&l=' + login + '&balloonNumbers=' + balloonNumbers + '&deliveryType=' + deliveryType + '&deliveryDate=' + deliveryDate, (userData) => {
            /* Переменные для хранения html кода подсказок */
            let bodyDeliveryType = '<span class=\'badge badge-primary text-uppercase py-1\'>Доставка</span> - подразумевает замену пустого баллона на полный<br>' + '<span class=\'badge badge-primary text-uppercase py-1\'>Замена</span><span> - когда полный баллон меняют на полный. Например, не подошел по штоку';
            let bodySum = '<div class=\'d-flex\'><span><i class=\'fas fa-ruble-sign\'></i></span><span class=\'mx-2\'>Цена за кг: </span><span id=\'span-price-for-kg\' class=\'badge badge-primary text-uppercase py-1 ml-auto\'>' + userData['price_for_kg'] + ' р.</span></div>' + '<div class=\'d-flex mt-1\'><span><i class=\'fas fa-weight\'></i></span><span class=\'mx-2\'>Вес баллона: </span><span id=\'span-balloon-weight\' class=\'badge badge-primary text-uppercase py-1 ml-auto\'>' + userData['balloon_weight'] + ' кг.</span></div>';

            inputBalloonType.val(userData['rate_name']);
            inputCurrentSum.val(userData['sum']);

            ui.tooltip('create', '#' + selectDeliveryType.attr('id'), bodyDeliveryType, 'mouseenter');
            ui.tooltip('create', '#' + divCurrentSumTooltip.attr('id'), bodySum, 'mouseenter');

            ui.onLoad('hide');
            ui.elementOnLoad(inputCurrentSum, 'off', 'grey');
            ui.elementOnLoad(inputBalloonType, 'off', 'grey');
        }, () => {
            ui.onLoad('hide');
            ui.toggleButtonState('off');
            ui.elementOnLoad(inputCurrentSum, 'off', 'grey');
            ui.elementOnLoad(inputBalloonType, 'off', 'grey');
            ui.showModalMessage('Внимание', 'Тариф не указан, обратитесь в техподдержку', 'warning');
        });
    });

    selectDeliveryDate.change(() => {
        let login = spanLogin.text();
        let balloonNumbers = inputBalloonNumbers.val();
        let deliveryType = selectDeliveryType.val();
        let deliveryDate = selectDeliveryDate.val();

        ui.toggleButtonState('off');
        ui.elementOnLoad(inputCurrentSum, 'on', 'grey');
        // ui.elementOnLoad(inputBalloonType, "on", "grey");
        ui.tooltip('hideAll');

        requestOrders.sendPost('app.php', 'optional', 'getSum' + '&l=' + login + '&balloonNumbers=' + balloonNumbers + '&deliveryType=' + deliveryType + '&deliveryDate=' + deliveryDate, (userData) => {
            /* Переменные для хранения html кода подсказок */
            let bodyDeliveryType = '<span class=\'badge badge-primary text-uppercase py-1\'>Доставка</span> - подразумевает замену пустого баллона на полный<br>' + '<span class=\'badge badge-primary text-uppercase py-1\'>Замена</span><span> - когда полный баллон меняют на полный. Например, не подошел по штоку';
            let bodySum = '<div class=\'d-flex\'><span><i class=\'fas fa-ruble-sign\'></i></span><span class=\'mx-2\'>Цена за кг: </span><span id=\'span-price-for-kg\' class=\'badge badge-primary text-uppercase py-1 ml-auto\'>' + userData['price_for_kg'] + ' р.</span></div>' + '<div class=\'d-flex mt-1\'><span><i class=\'fas fa-weight\'></i></span><span class=\'mx-2\'>Вес баллона: </span><span id=\'span-balloon-weight\' class=\'badge badge-primary text-uppercase py-1 ml-auto\'>' + userData['balloon_weight'] + ' кг.</span></div>';

            inputBalloonType.val(userData['rate_name']);
            inputCurrentSum.val(userData['sum']);

            ui.tooltip('create', '#' + selectDeliveryType.attr('id'), bodyDeliveryType, 'mouseenter');
            ui.tooltip('create', '#' + divCurrentSumTooltip.attr('id'), bodySum, 'mouseenter');

            ui.onLoad('hide');
            ui.elementOnLoad(inputCurrentSum, 'off', 'grey');
            // ui.elementOnLoad(inputBalloonType, "off", "grey");
        }, () => {
            ui.onLoad('hide');
            ui.toggleButtonState('off');
            ui.elementOnLoad(inputCurrentSum, 'off', 'grey');
            // ui.elementOnLoad(inputBalloonType, "off", "grey");
            ui.showModalMessage('Внимание', 'Тариф не указан, обратитесь в техподдержку', 'warning');
        });
    });

    inputModalDataProcessingCheckbox.click(() => {
        ui.toggleDataProcessingButton();
    });

    buttonRefreshOrdersList.click(() => {
        ui.onLoad('show');
        ui.toggleDataTableControlState('off');
        ui.getOrderList(true);
    });

    buttonCreateOrder.click(() => {
        if (ui.checkUserStatus) {
            ui.onLoad('show');
            let formatDate = moment(selectDeliveryDate.val(), 'YYYY-MM-DD');

            requestOrders.sendPost('app.php', 'optional', 'createOrder' + '&l=' + spanLogin.text() + '&balloonNumbers=' + inputBalloonNumbers.val() + '&deliveryDate=' + selectDeliveryDate.val() + '&deliveryType=' + selectDeliveryType.val() + '&orderComment=' + inputOrderComment.val(), (data) => {
                ui.showModalMessage('Заявка успешно создана', ui.orderDeliveryMessage(moment().format('DD.MM.YYYY'), formatDate.format('DD.MM.YYYY'), selectDeliveryType.val(), inputBalloonNumbers.val(), inputCurrentSum.val()), 'primary');
                selectDeliveryType.val('Доставка');
                inputBalloonNumbers.val(1);
                ui.getDataToCreateOrder();
                ui.getOrderList();

                // Отправляем смс
                requestOrders.sendPost('app.php', 'optional', 'sendSms' + '&l=' + spanLogin.text() + '&balloonNumbers=' + inputBalloonNumbers.val() + '&deliveryDate=' + formatDate.format('DD.MM.YYYY') + '&deliveryType=' + selectDeliveryType.val() + '&message=' + 'CreateOrder');
            }, () => {
                ui.showModalMessage('Ошибка', 'Обратитесь в тех поддержку', 'danger');
            });
        } else {
            ui.showModalMessage('Внимание', `Необходимо обратиться в контактный центр по номеру <br> 8 800 700-28-04, так как статус пользователя <span class='badge badge-warning'>${spanStatus.text()}</span>`, 'warning');
        }
    });

    buttonUpdateOrder.click(() => {

        let currentDate = moment(selectDeliveryDate.val(), 'YYYY-MM-DD').format('DD.MM.YYYY');
        let originalDate = moment(ui.originalDeliveryDate, 'DD/MM/YYYY').format('DD.MM.YYYY');
        let currentNumbers = inputBalloonNumbers.val();
        let originalNumbers = ui.originalBalloonNumbers;
        let currentType = selectDeliveryType.val();
        let originalType = ui.originalDeliveryType;
        let currentComment = inputOrderComment.val();
        let originalComment = ui.originalOrderComment;

        if (currentDate === originalDate) {
            if (currentNumbers < originalNumbers) {
                ui.onLoad('show');
                requestOrders.sendPost('app.php', 'optional', 'updateOrder' + '&l=' + spanLogin.text() + '&orderId=' + divOrder.data('order').orderId + '&documentDate=' + divOrder.data('order').createdDate + '&balloonNumbers=' + inputBalloonNumbers.val() + '&deliveryDate=' + selectDeliveryDate.val() + '&deliveryType=' + selectDeliveryType.val() + '&orderComment=' + inputOrderComment.val(), () => {
                    ui.showModalMessage('Успешно', `Заявка с номером ${divOrder.data('order').orderId} успешно изменена`, 'primary');
                    ui.updateOrderInputFieldsData('new');
                    ui.getDataToCreateOrder();
                    ui.getOrderList();

                    // Отправляем смс
                    requestOrders.sendPost('app.php', 'optional', 'sendSms' + '&l=' + spanLogin.text() + '&balloonNumbers=' + inputBalloonNumbers.val() + '&deliveryDate=' + selectDeliveryDate.val() + '&deliveryType=' + selectDeliveryType.val() + '&message=' + `Заявка с номером ${divOrder.data('order').orderId} успешно изменена`);
                }, () => {
                    ui.showModalMessage('Ошибка', 'Обратитесь в техподдержку', 'danger');
                });
            } else if (currentNumbers === originalNumbers) {
                if (currentType === originalType) {
                    if (currentComment === originalComment) {
                        ui.showModalMessage('Внимание', 'Данные не изменились, обновлять нечего', 'warning');
                    } else {
                        ui.onLoad('show');
                        requestOrders.sendPost('app.php', 'optional', 'updateOrder' + '&l=' + spanLogin.text() + '&orderId=' + divOrder.data('order').orderId + '&documentDate=' + divOrder.data('order').createdDate + '&balloonNumbers=' + inputBalloonNumbers.val() + '&deliveryDate=' + selectDeliveryDate.val() + '&deliveryType=' + selectDeliveryType.val() + '&orderComment=' + inputOrderComment.val(), () => {
                            ui.showModalMessage('Успешно', `Заявка с номером ${divOrder.data('order').orderId} успешно изменена`, 'primary');
                            ui.updateOrderInputFieldsData('new');
                            ui.getDataToCreateOrder();
                            ui.getOrderList();

                            // Отправляем смс
                            requestOrders.sendPost('app.php', 'optional', 'sendSms' + '&l=' + spanLogin.text() + '&balloonNumbers=' + inputBalloonNumbers.val() + '&deliveryDate=' + selectDeliveryDate.val() + '&deliveryType=' + selectDeliveryType.val() + '&message=' + `Заявка с номером ${divOrder.data('order').orderId} успешно изменена`);
                        }, () => {
                            ui.showModalMessage('Ошибка', 'Обратитесь в техподдержку', 'danger');
                        });
                    }
                } else {
                    ui.onLoad('show');
                    requestOrders.sendPost('app.php', 'optional', 'updateOrder' + '&l=' + spanLogin.text() + '&orderId=' + divOrder.data('order').orderId + '&documentDate=' + divOrder.data('order').createdDate + '&balloonNumbers=' + inputBalloonNumbers.val() + '&deliveryDate=' + selectDeliveryDate.val() + '&deliveryType=' + selectDeliveryType.val() + '&orderComment=' + inputOrderComment.val(), () => {
                        ui.showModalMessage('Успешно', `Заявка с номером ${divOrder.data('order').orderId} успешно изменена`, 'primary');
                        ui.updateOrderInputFieldsData('new');
                        ui.getDataToCreateOrder();
                        ui.getOrderList();

                        // Отправляем смс
                        requestOrders.sendPost('app.php', 'optional', 'sendSms' + '&l=' + spanLogin.text() + '&balloonNumbers=' + inputBalloonNumbers.val() + '&deliveryDate=' + selectDeliveryDate.val() + '&deliveryType=' + selectDeliveryType.val() + '&message=' + `Заявка с номером ${divOrder.data('order').orderId} успешно изменена`);
                    }, () => {
                        ui.showModalMessage('Ошибка', 'Обратитесь в техподдержку', 'danger');
                    });
                }
            } else if (currentNumbers > originalNumbers) {
                ui.finalCheckBeforeUpdateOrder('difference');
            }
        } else {
            ui.finalCheckBeforeUpdateOrder('original');
        }
    });

    buttonDeleteEditOrder.click(() => {
        ui.onLoad('show');
        ui.toggleDataTableControlState('off');
        requestOrders.sendPost('app.php', 'optional', 'deleteOrder' + '&l=' + spanLogin.text() + '&orderId=' + divOrder.data('order').orderId + '&documentDate=' + divOrder.data('order').createdDate, () => {
            ui.showModalMessage('Внимание', `Заявка от ${divOrder.data('order').createdDate} успешно удалена`, 'primary');
            ui.updateOrderInputFieldsData('new');
            ui.getDataToCreateOrder();
            ui.getOrderList();

            // Отправляем смс
            requestOrders.sendPost('app.php', 'optional', 'sendSms' + '&l=' + spanLogin.text() + '&balloonNumbers=' + inputBalloonNumbers.val() + '&deliveryDate=' + selectDeliveryDate.val() + '&deliveryType=' + selectDeliveryType.val() + '&message=' + `Заявка от ${divOrder.data('order').createdDate} успешно удалена`);
        }, () => {
            ui.showModalMessage('Ошибка', 'Обратитесь в тех поддержку', 'danger');
        });
    });

    buttonCloseEditButton.click(() => {
        ui.updateOrderInputFieldsData('new');
        ui.getDataToCreateOrder();
    });
    //endregion

    //region Действия выполняются только на странице заказов
    if (document.location.pathname === AppConfig.getRootPath(process.env.HOST) + 'app/modules/orders/') {
        // Логотип
        ui.getLogo(process.env.HOST, 'modules');
        // Ссылки
        ui.getDocumentsLinks(process.env.HOST);

        ui.getSessionInfo().then(r => {
            if (r.data['answer']['session_1CAccountType'] === 'Баллонный газ') {
                sessionStorage.setItem('user', JSON.stringify(r.data['answer']));

                tableOrdersList = $('#table-orders-list');
                ui.dt('create', tableOrdersList);
                ui.getDataToCreateOrder();
                ui.getOrderList();
                ui.checkOrderPayment(ui.url());
                ui.showDeliveryInfo(parseInt(inputBalloonNumbers.val()));
                ui.getPageParams();
            } else {
                ui.toggleButtonState('off');
                ui.showModalMessage('Внимание', 'Данному аккаунту эта функция не доступна. Перенаправление через 2 секунды.', 'danger');
                ui.goToPage(2000, AppConfig.getRootPath(process.env.HOST));
            }
        });
    } else if (document.location.pathname === AppConfig.getRootPath(process.env.HOST)) {
        // Логотип
        ui.getLogo(process.env.HOST);
        // Ссылки
        ui.getDocumentsLinks(process.env.HOST);
    }
    //endregion
});

//region Export
export {
    requestProfile,
    requestOrders,
    orderList,
    ui,
    divAuthorizationForm,
    divUserProfile,
    divCurrentSumTooltip,
    divOrder,
    pTextHeaderDescriptionProfile,
    divLoader,
    divModalDataProcessing,
    inputModalDataProcessingCheckbox,
    divModal,
    pModalTitle,
    spanModalHeaderBadge,
    divModalBody,
    buttonModalDismiss,
    buttonModalAdditional,
    selectRegion,
    inputID,
    inputFio,
    inputDistrict,
    inputCity,
    inputStreet,
    inputBuilding,
    inputApartment,
    inputPhone,
    inputSignInLogin,
    inputSignInPassword,
    inputPassword,
    inputPasswordRepeat,
    inputBalloonType,
    selectDeliveryType,
    inputBalloonNumbers,
    selectDeliveryDate,
    inputOrderComment,
    inputCurrentSum,
    labelCurrentSumPrepend,
    spanCurrentSumAppend,
    spanCreatedDateOrStatus,
    inputResetPassword,
    aShowResetForm,
    spanFio,
    spanPhone,
    spanLogin,
    spanStatus,
    spanRoute,
    spanAccount,
    buttons,
    buttonSignIn,
    buttonLogOut,
    buttonUpdateUser,
    buttonToOrders,
    buttonToProfile,
    buttonLogOutOrders,
    buttonAboutStatusDisclaimer,
    buttonUpdateUserPassword,
    buttonConfirmDataProcessing,
    buttonRefreshOrdersList,
    buttonCheckBalloonsNumber,
    buttonCreateOrder,
    buttonUpdateOrder,
    buttonDeleteEditOrder,
    buttonCloseEditButton,
    buttonResetPassword,
    tableOrdersList,
    tableOrderListObject,
    pHeaderText,
    iLoginHelp,
    divProfileRightSideInfo,
    divProfileRightSideButtons
};
//endregion

