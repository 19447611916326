import React, {Component} from 'react';
import UserInterface from '../../balloon.gas/app/lib/js/UserInterface';

class DeliveryInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // Все районы
            deliveryPrice: {
                9:  243.09,     // 9
                10: 243.09,     // 10
                18: 486.18,     // 18
                20: 486.18      // 20
            },
            // Спец районы, под знаком !
            deliveryPriceSpecialDistrict: {
                9:  203.09,     // 9
                10: 203.09,     // 10
                18: 406.18,     // 18
                20: 406.18      // 20
            }
        };

        this.componentDidMount = this.componentDidMount.bind(this);
    }

    componentDidMount() {
        let info = `Цена доставки на СУГ для газобаллонных установок в районах  Залесовский, 
        Заринский, Кытмановский, Бийский, Быстроистокский, Ельцовский, Красногорский, 
        Петропавловский, Смоленский, Советский, Солонешенский, Солтонский, Тогульский, 
        Троицкий, Целинный составит: <strong>${this.state.deliveryPriceSpecialDistrict[this.props.weight]} рублей</strong>.`;

        let ui = new UserInterface();
        ui.tooltip('create', '#delivery-warning-information', `${info}`, 'mouseenter');
    }

    render() {
        return (
            <div id="delivery-info-hidden-alert" className="alert alert-info mb-0 hidden-alert" role="alert">
                Цена доставки
                составит: <strong>{this.state.deliveryPrice[this.props.weight] * this.props.count}</strong> рублей.
                Доставку можно оплатить при получении.<br/>
                <span id="delivery-warning-information" className="text-right"><small>Важная информация!</small></span>
            </div>
        );
    }
}

export default DeliveryInfo;
