"use strict";

export default class Request {
    constructor(requestUrl) {
        this.requestUrl = requestUrl;
    }

    sendPost(path, elementID, method, successCallback, errorCallback, alwaysCallback){

        if (method === undefined || method === "") {
            console.log("Error: Empty method");
        } else {
            method = "&method=" + method;

            $.ajax({
                url: this.requestUrl + path,
                method: "POST",
                dataType: "JSON",
                data: $("#" + elementID).serialize() + method,
                success: (data) => {
                    if (typeof successCallback === "function") {
                        successCallback(data);
                    }
                },
            })
                .fail((jqXHR, textStatus, errorThrown) => {
                    // console.log("The following error occurred: " + textStatus, errorThrown);
                    // console.log(jqXHR);
                    if (typeof errorCallback === "function") {
                        errorCallback();
                    }
                })
                .always(() => {
                    if (typeof alwaysCallback === "function") {
                        alwaysCallback();
                    }
                });
        }
    }
}